import { put, takeEvery } from "redux-saga/effects";
import { GET_CHAT, GET_LOADING, GET_LOGO, SET_CHAT, SET_LOADING, SET_LOGO } from "../ConstAction";
import ApiRequests from "../../Services/Public";
// import { storage } from "../../Config/Storage";
function* getChat(data) {
  try {
    let response = yield ApiRequests.requestchat(data.data);

    yield put({
      type: SET_CHAT,
      response,
      loading: false
    });

    // yield HandleApiResponse(response, SET_NOTIFICATION_NUMBER, "Failed to Sign up");
    // if (response.status) {
    // if (data.data.query === "hi" || data.data.query === "hello") {
    //   yield put({
    //     type: SET_CHAT_HI,
    //     response,
    //   });
    // } else {
    //   yield put({
    //     type: SET_CHAT,
    //     response,
    //     loading: false
    //   });
    // }
    // } else if (response.status === 303) {
    // } else {
    // }
  } catch (err) { }
}
function* getLoading(data) {
  try {
    yield put({
      type: SET_LOADING,
      data: data.data,
    });
  } catch (err) { }
}
function* getLogo(data) {
  try {
    let response = yield ApiRequests.requestlogo(data.data);

    yield put({
      type: SET_LOGO,
      response,
    });
  } catch (err) { }
}
function* chatSaga() {
  yield takeEvery(GET_CHAT, getChat);
  yield takeEvery(GET_LOADING, getLoading);
  yield takeEvery(GET_LOGO, getLogo);
}
export default chatSaga;
