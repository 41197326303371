import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import rootSaga from "./rootSaga";
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,

  middleware: () => [sagaMiddleware],
});
sagaMiddleware.run(rootSaga);
export default store;
