import { replace } from "formik";
import { BUY_NEW_TOKENS, DASHBOARD, REQUEST_HISTORY } from "../Routes/constURL";
import Request from "../../src/assests/request 1.svg";
import Coupon from "../../src/assests/coupon.svg";
import logoutIcon from "../../src/assests/logout.svg";
import dashboardIcon from "../../src/assests/Frame.svg";

export const Sidebar = [
  {
    val: "Pannello di controllo",
    routes: DASHBOARD,
    status: false,
    src: dashboardIcon,
  },
  {
    val: "Richiedi la cronologia",
    routes: REQUEST_HISTORY,
    status: false,
    src: Request,
  },
  {
    val: "Acquista nuovi token",
    routes: BUY_NEW_TOKENS,
    status: false,
    src: Coupon,
  },
  {
    val: "Disconnessione",
    status: false,
    src: logoutIcon,
  },
];

export const chat = [];

export const DashbordCardContent = [
  {
    id: 1,
    heading: "Identificativo Client",
    dec: "EXT_CLIENT_987654321",
    buttonText: "Active",
    ChidCardeading: [
      {
        id: 1,
        mainText: "Richieste Disponibili:",
        desc: "2600",
      },
      {
        id: 2,
        mainText: "Data Scadenza:",
        desc: "26/03/2025",
      },
      {
        id: 3,
        mainText: "Data dell'ultimo acquisto del token:",
        desc: "26/03/2025",
      },
    ],
  },
  {
    id: 2,
    heading: "Identificativo Client",
    dec: "EXT_CLIENT_987654321",
    buttonText: "Active",
    ChidCardeading: [
      {
        id: 1,
        mainText: "Richieste Disponibili:",
        desc: "2600",
      },
      {
        id: 2,
        mainText: "Data Scadenza:",
        desc: "26/03/2025",
      },
      {
        id: 3,
        mainText: "Data dell'ultimo acquisto del token:",
        desc: "26/03/2025",
      },
    ],
  },
];
