import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Heading from "../Components/Heading";
// import { DashbordCardContent } from "../Config/MockData";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard } from "../Redux/Private/PrivateAction";
import { storage } from "../Config/Storage";
import Toastify from "../Components/SnackBar/Toastify";
import { useTheme } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const Dashboard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { details } = useSelector((state) => state?.PrivateData) || {};
  const isImg = useMediaQuery(theme.breakpoints?.down("md"));
  const [data, setData] = useState();
  useEffect(() => {
    const contractKey = storage.fetch.contractKey();
    dispatch(getDashboard({ contractKey }));
  }, []);
  useEffect(() => {
    if (details?.services) {
      const updatedDetails = {
        ...details,
        services: details.services.map((item) => {
          let date = new Date(item.expirationDate * 1000); // Convert seconds to milliseconds

          // Extract day, month, and year
          let day = String(date.getUTCDate()).padStart(2, "0");
          let month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
          let year = date.getUTCFullYear();

          // Format the date to DD/MM/YYYY
          item.expiration = `${day}/${month}/${year}`;
          return item;
        }),
      };
      // Set the updated details to state or use another mechanism to update
      setData(updatedDetails);
    }
  }, [details]);

  return (
    <>
      <Grid>
        <Heading name={"Cruscotti"} />
        <Grid sx={Style.UpperGrid}>
          <Box>
            <Typography sx={Style.welcometext}>Benvenuto {details?.customerName} !</Typography>
            <Typography sx={Style.smalltext}>Siamo entusiasti di averti con noi. Divertiti ad esplorare!</Typography>
          </Box>
          <img
            src={require("../assests/automated-customer-service-platforms-use-data-from-past-interactions-provide-more-accurate 1.png")}
            style={{
              right: isImg ? "-15px" : "54px",
              height: "263px",
              position: "absolute",
              objectFit: "cover",
              bottom: "0px",
              display: isImg ? "none" : "block"
            }}
          />
        </Grid>
        <Toastify />
        <Grid container spacing={2} mb={2}>
          {data?.services?.map((item) => (
            <Grid item xs={12} md={6} key={item.clientId}>
              <Item style={Style.lowerGrid}>
                <Box style={Style.lowerGridWrapper}>
                  <div>
                    <Typography sx={Style.lowerGridText}>{item.serviceName}</Typography>
                    <Typography sx={Style.lowerGridDesc}>{item.clientId}</Typography>
                  </div>
                  <Box style={Style.lowerGridButton}>{item.status}</Box>
                </Box>
                <Grid container spacing={{ xs: 2, md: 4}} columns={{ xs: 4, sm: 6, md: 12 }} style={Style.lowerGridChildBoxWrapper}>
                  <Grid item xs={6} sm={6} md={4}>
                    <Item sx={Style.lowerGridChildBox}>
                      <Typography sx={Style.lowerGridChildBoxText}>Richieste Disponibili:</Typography>
                      <Typography sx={Style.lowerGridChildBoxDes}>{item.availableTokens}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Item sx={Style.lowerGridChildBox}>
                      <Typography sx={Style.lowerGridChildBoxText}  style={{width:'100px'}}>Data Scadenza:</Typography>
                      <Typography style={Style.lowerGridChildBoxDes}>{item.expiration}</Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Item sx={Style.lowerGridChildBox}>
                      <Typography sx={Style.lowerGridChildBoxText}>Data dell'ultimo acquisto del token:</Typography>
                      <Typography sx={Style.lowerGridChildBoxDes}>{item.expiration}</Typography>
                    </Item>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
const Style = {
  UpperGrid: {
    background: "linear-gradient(90deg, #EFEEFF 0%, #F5F4FF 45.9%, #EEFDFF 100%);",
    width: "100%",
    p: 6,
    borderRadius: "10px",
    mt: 4,
    display: "flex",
    position: "relative",
  },
  welcometext: {
    color: "#343568",
    fontSize: { sm: "15px", md: "15px", lg: "30px" },
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    textTransform: "uppercase",
  },
  smalltext:{
    wordBreak: "break-word",
    width: "270px",
    marginTop: "10px",
    fontSize:'18px'
},
  desp: {
    color: "#343568",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  // img: {
  //   height: "200px",
  //   position: "absolute",
  //   right: "54px",
  //   objectFit: "cover",
  //   bottom: "0px",
  // },
  lowerGrid: {
    background: "linear-gradient(90deg, #EFEEFF 0%, #F5F4FF 45.9%, #EEFDFF 100%)",
    borderRadius: "15px",
    marginTop: "20px",
    boxShadow: "none",
  },
  lowerGridText: {
    color: "#343568",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "21.11px",
  },
  lowerGridDesc: {
    color: "#343568",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "29.77px",
  },
  lowerGridWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  lowerGridButton: {
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16.42px",
    borderRadius: "60px",
    paddingTop: "7px",
    textAlign: "center",
    backgroundColor: "#28A745",
    gap: "10px",
    width: "90px",
    height: "30px",
    textTransform: "Capitalize",
  },
  lowerGridChildBoxWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: "16px",
  },
  lowerGridChildBox: {
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    padding: "15px",
    marginTop: "10px",
    // width: "138px",
    height: "108px",
    boxShadow: "rgba(41, 19, 253, 0.25) 4px 4px 4px 0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  lowerGridChildBoxText: {
    color: "#343568",
    fontWeight: 600,
    fontSize: { sm: "13px", md: "13px", lg: "14px" },
    lineHeight: "16.42px",
    textTransform: "capitalize",
  },
  lowerGridChildBoxDes: {
    fontWeight: 700,
    fontSize: { sm: "18px", md: "14px", lg: "14px" },
    lineHeight: "21.11px",
    color: "#343568",
    // marginTop: "10px",
    textAlign: "right",
  },
};
