import { GET_DASHBOARD, GET_LOGIN } from "../ConstAction";

export const getLogin = (data, navigate) => {
  return {
    type: GET_LOGIN,
    data: data,
    navigate: navigate,
  };
};
export const getDashboard = (data) => {
  return {
    type: GET_DASHBOARD,
    data: data,
  };
};
