import React from "react";
import { Box, Grid } from "@mui/material"
const WorkInProgress = () => {
  return (
    <Grid container>
      <Box style={{ display: "flex", justifyContent: "center"}} sx={{width:{sm:'100vw'}}}>
        <img
          alt="Workinprogress"
          className="workInProgress"
          style={{width:"50%"}}
          // src={vehicData?.photo_links[selected]?.image_url}
          src={require("../assests/work.jpg")}

        // onClick={() => setOpen(true)}
        />
      </Box>
    </Grid>
  );
};

export default WorkInProgress;
