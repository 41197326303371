import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        color: "#fff",
        backgroundColor: "#27245E",
        position: "fixed",
        bottom: "0",
        zIndex: "9999",
        textAlign: "center",
        fontSize: "12px",
        p: 2,
        display: "flex",
        justifyContent: "center",
      }}>
      <footer style={{ display: "flex" }}>
        <img src={require("../assests/w-logo 1@2x.png")} height={"20px"} width={"30px"} />
        <Typography sx={{ color: "#FFFFFF", ml: 2 ,fontSize:'14px'}}>Developed by Clink S.R.L</Typography>
      </footer>
    </Box>
  );
};

export default Footer;
