import { SET_DASHBOARD, SET_LOADING, SET_LOGIN } from "../ConstAction";
const initialState = {};
export const PrivateData = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN:
      return {
        details: action.response.data,
        loading: false,
      };
    case SET_DASHBOARD:
      return {
        details: action.response.data,
        loading: false,
      };
    case SET_LOADING:
      return {
        loading: action.data,
      };
    default:
      return null;
  }
};
