import { API_URL } from "../../Config/ApiUrl";
import { Api } from "../../Config/Request";
export const Login = async (data) => {
  return Api.PostRequest(API_URL + "userLogin", data, false);
};
export const requestDashboard = async (data) => {
  return Api.GetAuthRequest(API_URL + `dashboard/${data.contractKey}`);
};
const PrivateApiRequests = {
  Login,
  requestDashboard,
};
export default PrivateApiRequests;
