import * as yup from "yup";

export const Schema = {
  LOGIN: yup.object().shape({
    username: yup.string().required("Inserisci il tuo nome utente"),
    password: yup
      .string()
      .required("per favore inserisci LA TUA password")
      .min(8, "La password è troppo corta: deve contenere almeno 8 caratteri"),
  }),
};
