import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import store from "./Redux/store.js";
import { Provider } from "react-redux";
import Footer from "./Components/Footer.js";
const root = ReactDOM.createRoot(document.getElementById("root"));
// const sagaMiddleware = createSagaMiddleware();
// sagaMiddleware.run(mySaga);
// const rootReducer = combineReducers({myFirstReducer}); //in case you have more than one reducer. Don't forget to import it from @reduxjs/toolkit
// const store = configureStore({ reducer: MyReducer, middleware: [sagaMiddleware]  });

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <Footer />
    </BrowserRouter>
  </Provider>
);
