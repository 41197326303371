import React from "react";
import { ToastContainer } from "react-toastify";
// import { css } from 'glamor';
import "react-toastify/dist/ReactToastify.css";

const Toastify = () => {
  return (
    <div style={{ zIndex: 99999 }}>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        // toastStyle={customToast}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{
          marginBottom: "2rem",  // Default margin for all screens
        }}
      />
    </div>
  );
};

export default Toastify;
