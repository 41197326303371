import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import { Sidebar } from "../Config/MockData";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { LOGIN } from "../Routes/constURL";
import AlertDialog from "./AlertDialog";
import Index from "../Routes";
import CloseIcon from "@mui/icons-material/Close";
import { storage } from "../Config/Storage";
import { toast } from "react-toastify";

const CompDrawer = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints?.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints?.down("lg"));
  const isLargeDevice = useMediaQuery(theme.breakpoints?.down("lg"));
  const [open, setOpen] = React.useState(isMobile || isIpad ? false : true);
  const [openLogoutAlert, setOpenLogoutAlert] = React.useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1200) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }

    // Attach the event listener to the window object
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Logout = () => {
    setOpenLogoutAlert(true);
  };

  const logOutAdmin = () => {
    setOpenLogoutAlert(false);
    navigate(LOGIN);
    storage.destory.auth();
    storage.destory.contractKey();
    setTimeout(() => {
      toast.success("Logged out succesfully.");
    }, 2000);
    // localStorage.clear();
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        sx={{
          bgcolor: "#ffffff",
          display: "flex",
          boxShadow: "none",
          borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
          pt: 1.5,
          pb: 1.5,
          "&.MuiAppBar-root": {
            position: "unset",
          },
        }}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "end",
            "&.MuiToolbar-root":{
            minHeight:"16px"
            }
          }}>
          {!open && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                color: "#B2C1F0",
                display: { xs: "flex", lg: "none" },
                width: "50px",
                marginRight: "auto",
                height: "50px",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <MenuIcon sx={{ fontSize: "35px" }} />
            </IconButton>
          )}
          <Box
            sx={{
              border: 1,
              borderRadius: "60px",
              borderColor: "black",
              width: "50px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              justifyContent: "flex-end",
            }}>
            <img
              style={{
                height: "50px",
                width: "80px",
                borderRadius: "60px",
              }}
              src={require("../assests/user.png")}
            />
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        onClose={() => setOpen(false)}
        sx={{
          minWidth: "300px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "300px",
            boxSizing: "border-box",
          },
          ml: "30px",
          mr: "30px",
        }}
        id="drawer"
        variant={isLargeDevice ? "temporary" : "persistent"}
        anchor="left"
        open={open}>
        <Box sx={{ m: 2, display: "flex", maxHeight: "200px", position: "relative" ,    marginTop: "27px"}}>
          <img width={"89px"} height={"61px"} src={require("../assests/w-logo 1@2x.png")} />
          <Box
            sx={{
              ml: 2,
              mt: 1,
              mb: 3,
            }}>
            <Typography sx={{ color: "#343568", fontSize: "20px", fontWeight: 700 }}>WORX</Typography>
            <Typography
              sx={{
                color: "#343568",
                fontSize: "18px",
                fontWeight: 600,
              }}>
              AI Assistant
            </Typography>
          </Box>
          {open && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerClose}
              edge="start"
              sx={{
                color: "#B2C1F0",
                display: { xs: "flex", lg: "none" },
                ml: "5px",
                position: "absolute",
                right: "0px"
              }}>
              <CloseIcon sx={{ fontSize: "35px" }} />
            </IconButton>
          )}
        </Box>
        <List
          className="drawerItem"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            "& .MuiListItemButton-root:hover": {
              // bgcolor: "white",
              borderRadius: "8px",
              fontWeight: "400",

              "&, & .MuiListItemIcon-root": {
                // color: "#3D2E57",
                fontWeight: "400",
                borderRadius: "8px",
              },
            },
            p: { xs: "0px 10px 0px 10px", sm: "0px 25px 8px 25px" },
          }}>
          {Sidebar.map((text, index) => (
            <ListItem
              sx={{
                bgcolor: text?.isActive ? "#27245E" : "#EEF2FC",
                borderRadius: text?.isActive ? "8px" : "8px",
                // border: "1px solid #E0E0E0",
                backgroundImage: "linear-gradient(to right,#E9EBFF, #F6F2FF,#EEFDFF)",
                mb: "15px",
              }}
              key={index}
              disablePadding>
              <NavLink
                className={`drawerItemLinks ${index === Sidebar.length - 1 ? "no-active" : ""} ${index === Sidebar.length - 2 ? "disabled-link" : ""}`}
                style={{
                  color: text?.isActive ? "white" : "#27245E",
                  width: "100%",
                  textDecoration: "none",
                  display: "flex",
                  padding: " 6px 12px",
                  fontSize: "16px",
                }}
                to={text?.routes}
                onClick={() => (index === Sidebar.length - 1 ? Logout() : "", isMobile ? setOpen(false) : null)}>
                <ListItemIcon
                  sx={{
                    minWidth: "35px",
                  }}>
                  <img
                    style={{
                      width: "18px",
                      filter: text?.isActive
                        ? "invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)"
                        : "",
                    }}
                    alt="profile"
                    src={text?.src}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  primary={text?.val}
                />
              </NavLink>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        sx={{
          width: {
            lg: open ? `calc(100% - 300px)` : "100%",
            xs: "100%",
          },
          ml: open ? "auto" : "",
        }}
        className={open && isIpad ? "hightlight-body" : ""}>
        <Box sx={{ p: { xs: "40px 15px 40px 15px", sm: "20px 40px 40px 40px" } }}>
          <Index />
        </Box>
      </Box>
      <Box>
        <AlertDialog
          title={"Sei sicuro di voler uscire?"}
          open={openLogoutAlert}
          onClickButtonCancel={() => setOpenLogoutAlert(false)}
          onClickButton={() => logOutAdmin()}
        />
      </Box>
    </>
  );
};

export default CompDrawer;
