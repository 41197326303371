import Login from "../Private/Login";
import Chatarea from "../Public/Chatarea";
import { BUY_NEW_TOKENS, CHATAREA, DASHBOARD, LOGIN, NODATAFOUND, REQUEST_HISTORY, SOMETHING_WENT_WRONG } from "./constURL";
import ProtectedRoute from "./protectedRoutes";
import Dashboard from "../Private/Dashboard";
import SomethingWrong from "../Components/SomethingWrong";
import NoDataFound from "../Components/NoDataFound";
import WorkInProgress from "../Components/WorkInProgress";
import OpenRoutes from "./openRoutes";
export const globalRoutes = [
  {
    path: CHATAREA,
    element: Chatarea,
    routeType: OpenRoutes,
  },
  {
    path: LOGIN,
    element: Login,
    routeType: OpenRoutes,
  },
  {
    path: DASHBOARD,
    element: Dashboard,
    routeType: ProtectedRoute,
  },
  {
    path: REQUEST_HISTORY,
    element: WorkInProgress,
    routeType: ProtectedRoute,
  },
  {
    path: BUY_NEW_TOKENS,
    element: WorkInProgress,
    // routeType: ProtectedRoute,
  },

  {
    path: NODATAFOUND,
    element: NoDataFound,
    routeType: ProtectedRoute,
  },
  {
    path: SOMETHING_WENT_WRONG,
    element: SomethingWrong,
    routeType: ProtectedRoute,
  },
];
