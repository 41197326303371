import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { LOGIN } from "./constURL";
import { storage } from "../Config/Storage";

const ProtectedRoute = ({ children }) => {
  let acc_Token;
  acc_Token = storage.fetch.auth();
  if (acc_Token) {
  } else {
    setTimeout(() => {
      localStorage.clear();
    }, 2000);

    return <Navigate to={LOGIN} replace />;
  }
  return children;
};

export default ProtectedRoute;
