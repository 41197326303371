import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

const TypingText = ({ text, chatEndRef, setCheckTypingStatus }) => {
  const [displayedText, setDisplayedText] = useState("");

  let displaytext = "";

  useEffect(() => {
    if (chatEndRef.current) {
      const scrollHeight = chatEndRef.current.scrollHeight;
      const clientHeight = chatEndRef.current.clientHeight;
      const scrollToHeight = scrollHeight - clientHeight;
      chatEndRef.current.scrollTo(0, scrollToHeight);
    }
  }, [displayedText]);

  useEffect(() => {
    let index = 0;
    setCheckTypingStatus(false)
    const intervalId = setInterval(() => {
      if (index < text.length) {
        displaytext += text[index]
        setDisplayedText(displaytext)
        index++;
      } else {
        clearInterval(intervalId);
        setCheckTypingStatus(true)
      }
      if (chatEndRef.current) {
        chatEndRef.current.scrollTo({
          top: chatEndRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 10); // Adjust typing speed here


    return () => clearInterval(intervalId);
  }, [text]);

  return (
    <>
      <ReactMarkdown>{displayedText}</ReactMarkdown>
    </>
  )
};

export default TypingText;
