import logo from "./logo.svg";
import "./App.css";
import Index from "./Routes/index";
import Chatarea from "./Public/Chatarea";
import { ThemeProvider, createTheme } from "@mui/material";
import Footer from "./Components/Footer";
import { useLocation } from "react-router-dom";
import { CHATAREA, LOGIN } from "./Routes/constURL";
import CompDrawer from "./Components/Drawer";
const THEME = createTheme({
  typography: {
    fontFamily: ["Work Sans", "sans-serif"].join(","), // Default font family for normal text
    // Add more heading styles as needed
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          background: "transparent",
        },
      },
    },
  },
  TextField: {
    paddingLeft: "0px",
  },
});
function App() {
  const location = useLocation();
  return (
    <ThemeProvider theme={THEME}>
      {location.pathname === LOGIN || location.pathname === CHATAREA ? <Index /> : <CompDrawer />}
    </ThemeProvider>
  );
}

export default App;
