import { Typography } from "@mui/material";
import React from "react";

const Heading = ({ name }) => {
  return (
    <Typography
      sx={{
        color: "#343568",

        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        textTransform: "uppercase",
      }}>
      {name}
    </Typography>
  );
};

export default Heading;
