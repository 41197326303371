import { put, takeEvery } from "redux-saga/effects";
import {
  GET_DASHBOARD,
  GET_LOGIN,
  SET_DASHBOARD,
  SET_LOGIN,
} from "../ConstAction";
import { storage } from "../../Config/Storage";
import { toast } from "react-toastify";
import PrivateApiRequests from "../../Services/Private";
import { DASHBOARD } from "../../Routes/constURL";
function* getLogin(data) {
  try {
    let response = yield PrivateApiRequests.Login(data.data);
    if (response.status === 200) {
      storage.set.authToken(response.data.authToken);
      storage.set.contractKey(response.data.activeContractKey);
      data.navigate(DASHBOARD);
      yield put({
        type: SET_LOGIN,
        response,
      });
      setTimeout(() => {
        toast.success("User Logged in Successfully");
      }, 1000);
    }
  } catch (err) {}
}
function* getDashboard(data) {
  try {
    let response = yield PrivateApiRequests.requestDashboard(data.data);
    if (response.status) {
      yield put({
        type: SET_DASHBOARD,
        response,
      });
    }
  } catch (err) {}
}
function* privateSaga() {
  yield takeEvery(GET_LOGIN, getLogin);
  yield takeEvery(GET_DASHBOARD, getDashboard);
}
export default privateSaga;
